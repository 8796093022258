import React from 'react';
import styled from 'styled-components';

import { ReactComponent as UfoIcon } from '../assets/svgs/ufo.svg';

import { STYLES } from '../enums';
import SEO from '../components/layout/seo';

const UfoContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
text-align: center;
@media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

svg {
  width: 50vw;
  @media screen and (max-width: ${STYLES.breakpoints.padWidth}px) {
    margin-bottom: 30px;
    width: 80vw;
}   
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 15px;
  }
`;

const NotFoundPage = () => (
  <UfoContainer>
    <SEO title="404" shouldIndex={false} />
    <UfoIcon />
    <Center>
      <h1>Uuuuuuups!</h1>
      <h2>Diese Seite existiert nicht...</h2>
      <p>
        Vermutlich ist da etwas schiefgelaufen,
        <a href="/">{' hier geht es zurück zum Start.'}</a>
      </p>
    </Center>
  </UfoContainer>
);

export default NotFoundPage;
